<template>
  <div>
    <MyAccountOrdersFilter :number-materials="orders.length" />
    <RikazGrid name="orders" :items="orders">
      <template #card="{ forKey, data }">
        <MyAccountOrdersCard
          :key="`order-grid-item-${forKey}`"
          class="h-auto"
          :bill="(data as UserOrder) "
        />
      </template>
    </RikazGrid>
    <ClientOnly>
      <MevcutInfiniteScroll
        class="my-5"
        :infinite-id="infiniteId"
        @load-more="loadMore"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import getAllRoutes from '~/composables/AppApiRoutes'
import type { UserOrder } from '~~/composables/useMenuModel'

const props = defineProps({
  itemsPerPage: { type: Number, default: 10 },
  fetchParams: { type: Object, default: () => {} },
  withQueryParams: { type: Boolean, default: true }
})

const orders = ref<UserOrder[]>([])
const toast = useToast()
const route = useRoute()

const { t } = useI18n()

const { infiniteId, scrollFetch, loadMore, reset } = useInfiniteScroll(
  0,
  props.itemsPerPage,
  fetchOrders,
  appendOrders,
  clearOrders
)

await scrollFetch()

if (props.withQueryParams) {
  watch(
    () => route.query,
    () => reset()
  )
}

async function fetchOrders(state: ScrollState) {
  let queryParams = {}
  if (props.withQueryParams) {
    queryParams = { ...route.query }
  }

  try {
    const { data, error } = await useBasicFetch<ApiResponse<[]>>(getAllRoutes().billsRoutes.getBillsByServer, {
      query: {
        offset: `${state.offset}`,
        limit: `${state.limit}`,
        ...queryParams,
        ...props.fetchParams
      }
    })

    if (error?.value) {
      throw error.value
    }

    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('orders') }), { timeout: 1000 })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('orders') })
      } as ApiResponse<[]>
    }
  }
}

function appendOrders(data: []) {
  orders.value = [...orders.value, ...data]
}
function clearOrders() {
  orders.value = []
}
</script>
