<template>
  <div
    v-if="
      (selectedFilter == t('all_orders') && numberMaterials > 0) ||
        selectedFilter != t('all_orders')
    "
    class="mb-3"
  >
    <div class="flex justify-end mx-3">
      <v-menu>
        <template #activator="{ props }">
          <div class="flex mb-2 cursor-pointer" v-bind="props">
            <v-icon icon="mdi mdi-filter-outline" :size="24" />
            <div>
              {{ selectedFilter }}
            </div>
          </div>
        </template>
        <v-list class="mx-auto">
          <div
            v-for="filter in arrayFilters"
            :key="`filter-item-${filter.title}`"
            class="hoverItem"
            @click="selectFilter(filter.value)"
          >
            {{ filter.title }}
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
function selectFilter(id?: number) {
  if (route.query.currently?.toString() !== id?.toString()) {
    useQueryNavigation({ currently: id })
  }
}
defineProps<{ numberMaterials: number }>()
const arrayFilters = [
  { title: t('all_orders'), value: undefined },
  { title: t('current_orders'), value: 1 },
  { title: t('pervious_orders'), value: 0 }
]

const selectedFilter = computed(() => {
  return arrayFilters?.find(
    (el) => el.value?.toString() === route.query.currently?.toString()
  )?.title
})
</script>
<style scoped>
.hoverItem {
  @apply hover:bg-nav_color hover:bg-opacity-10 px-3 py-1 cursor-pointer;
}
</style>
