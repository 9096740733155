<template>
  <MyAccountCheckAuthLoggedIn>
    <MyAccountOrdersGrid />
  </MyAccountCheckAuthLoggedIn>
</template>
<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()

useHead({
  title: t('orders'),
  link: [useSeoCanonical(url + '/my-account/orders')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(
        3,
        t('orders'),
        url + '/my-account/orders'
      )
    ])
  ]
})
</script>
